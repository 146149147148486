import React, { useEffect, useState } from "react";
import moment from 'moment';
import { get } from 'lodash';

import api from "../../actions/api";
import Media from "../Media";
import Spinner from '../../components/Spinner';
import { UploadIcon } from "../../helpers/Icons";
import { showToast } from "../../helpers/notify";

import style from './style.module.css';

function ProfileUploader(props) {
    const { input: { onChange, value = "" }, validateDimension = false, dimension = "", previewLetter = false, inputRef = false, deleteBtnRef = false } = props;
    const [upload, setUpload] = useState("");
    const [showimage, setShowimage] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (upload !== value) {
            setUpload(value);
            onChange(value);
            setShowimage(value);
        }
    }, [props.input]);

    useEffect(() => {
        if (upload !== "") {
            uploadImage(upload);
        }
    }, [upload]);

    const extension = (name) => {
        var ext = name.substr((name.lastIndexOf('.') + 1));
        return ext.toLowerCase();
    }

    const validate = (file, formats, sizelimit) => {
        if (file) {
            const ext = extension(file.name);
            let fileAttr = {
                name: file.name,
                orgname: file.name,
                size: file.size,
                fa: ''
            }
            let data = {};
            if (formats !== "*" && !formats.includes(ext)) {
                data = {
                    valid: false,
                    reason: file.name + ' is invalid. Please upload a valid file',
                    file: fileAttr
                }
            }
            else if (file.size > sizelimit * 1024 * 1024) {
                data = {
                    valid: false,
                    reason: 'Please upload a file of size less than ' + sizelimit + 'MB',
                    file: fileAttr
                }
            }
            else {
                data = {
                    valid: true,
                    reason: "Success",
                    file: fileAttr
                }
            }
            return data;
        }
    }

    const uploadImage = (v) => {
        const { file } = v;
        const validation = validate(file, ['jpg', 'jpeg', 'png', 'svg'], 20);
        if (get(validation, 'valid', false)) {
            setFetching(true);
            var fd = new FormData();
            fd.append('file', file);
            fd.append('fileobject', true);
            api.post('/media/create/', fd, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    const { status = 400, data } = response;
                    if (status === 200) {
                        setShowimage(data);
                        setUpload(data);
                        onChange(data);
                        setFetching(false);
                    }
                })
        }
        else if (get(validation, 'reason', false)) {
            setFetching(false);
            showToast(get(validation, 'reason', ''));
        }
    }

    const onFileChangeHandler = (files) => {
        if (files.length > 0) {
            if (validateDimension && dimension !== "") {
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        let width = parseInt(dimension.split('x')[0]);
                        let height = parseInt(dimension.split('x')[1]);
                        if (this.width === width && this.height === height) {
                            setUpload({ filetime: moment().format('X'), file: files[0] });
                        }
                        else {
                            // setError(`Image resolution must be ${width}x${height}`);
                        }
                    }
                }
                return false
            } else {
                setUpload({ filetime: moment().format('X'), file: files[0] });
            }
        }
    }

    return <div className={`${style.profilePicture} ${inputRef ? ` ${style.customUpload}` : ''}`}>
        {
            !fetching && (
                <div className={`${style.uploadIcon}`}>
                    <input type="file" onChange={(e) => { onFileChangeHandler(e.target.files) }} ref={inputRef ? inputRef : null} />
                    <UploadIcon />
                </div>
            )
        }

        <div className={style.picture}>
            {showimage === false && previewLetter !== false && previewLetter !== null ?
                <div className={style.letter} dangerouslySetInnerHTML={{ __html: previewLetter.substring(1, 0) }} />
                :
                <Media brokenIcon={`fa-user`} src={showimage} size="200x200" type="image" lazy={false} />
            }
        </div>
        {
            fetching && (
                <Spinner />
            )
        }
    </div>
}

export default ProfileUploader;