import emojiRegex from 'emoji-regex';
import { get, isEmpty, isNil } from 'lodash';

export function cleanInput(value, removeEmoji = true) {
    if (!value) {
        return "";
    }
    if (removeEmoji === true) {
        const emojis = emojiRegex();
        return value.toString().replace(emojis, "");
    }
    return value;
}

export function displaySelectValue(state, props) {
    const { value, prompt } = state;
    const { list } = props;

    let item = list.find(d => d.id === value);
    return item ? item.text : prompt
}

export function getSelectedItem(state, props) {
    const { value, prompt, disabled = false } = state;
    const { list } = props;

    if (disabled === true || value === "" || isNaN(value)) {
        return { "": prompt };
    }

    let item = list.find(d => d.id === value);
    return item ? { id: item.id, text: item.text } : { "": prompt }
}

export function deleteFromObjectByValue(obj, value) {
    let data = Object.assign({}, obj);
    Object.entries(data).map(([k, v]) => {
        //Delete all entries that have an "all" as API doesn't support "all"
        if (v === value) {
            delete data[k];
            return true;
        }
        return false;
    });
    return data;
}

export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function isValidValue(value) {
    return !isNil(value) && !isEmpty(value) && value !== "null" && value !== "NULL";
}

export const getPaymentCycle = (cycle) => {
    const cycles = {
        "annual": "Annually",
        "month": "Monthly",
    };
    return get(cycles, cycle, 'Annually');
}