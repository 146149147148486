import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import $ from 'jquery-slim'
import GtagManager from '../../components/GtagManager';

const Light = () => {
    useEffect(() => {
        $("html").removeClass(`darkTheme`).addClass(`lightTheme`)
    }, [])
    const { pathname = "" } = useLocation();
    return (
        <>
            <Outlet context={{ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight }} />
            <GtagManager url={pathname} />
        </>
    )
}

export default Light;