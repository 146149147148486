import React from 'react';
import style from './style.module.css';

export default class InputTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.input.value || []
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (value !== prevProps.input.value) {
            this.setState({
                list: value || []
            })
        }
    }

    render() {
        const { label, required, meta: { error, touched } } = this.props;
        const { list } = this.state;
        return (
            <div className={`${style.formGroup}${touched && error ? " " + style.inputError : ""}`}>
                <div className={`${style.textbox} ${style.tags}`}>
                    {
                        list.map((item, i) => (
                            <div className={style.tag} key={`${i}_${item.id}_${label}`}>
                                <span>{item.text}</span>
                            </div>
                        ))
                    }
                </div>
                <div className={`${style.controlLabel} ${style.focus}`}>
                    <span>{label}</span>
                    {
                        required && (
                            <span className={style.required}> *</span>
                        )
                    }
                </div>
                {
                    touched && error && (
                        <p className={style.helpBlock}>{error}</p>
                    )
                }
            </div>
        )
    }
}