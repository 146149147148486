import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import $ from 'jquery';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCookie, getCookie } from '../../helpers/storage';
import DialogBox from '../../components/DialogBox';
import { Button } from '../../components/Field';
import { get } from 'lodash';
import GtagManager from '../../components/GtagManager';
import { getUser } from '../../actions';

const DarkGuest = () => {
    const [dimesions, setDimensions] = useState({ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight });
    const token = getCookie() || false;
    const newUser = getCookie("newUser");
    const [showPopup, setPopup] = useState(false);
    const { pathname = "" } = useLocation();
    const { data = {} } = useSelector((state) => state.storage.user);
    const dispatch = useDispatch();

    useEffect(() => {
        $("html").removeClass(`lightTheme`).addClass(`darkTheme`);
        function updateDimensions() {
            setDimensions({
                height: $(`.${style.header}`).outerHeight(),
                winWidth: $(window).width(),
                winHeight: $(window).height()
            });
        }
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('scroll', handleHeaderAnimation);
        updateDimensions();
        return () => {
            window.removeEventListener('scroll', handleHeaderAnimation);
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if (newUser) {
            setPopup("welcome-popup");
        }
    }, [])

    useEffect(() => {
        $('html,body').animate({ scrollTop: 0 }, 1000);
    }, [pathname])

    useEffect(() => {
        if (token !== false && token !== "") {
            dispatch(getUser());
        }
    }, [token]);

    const handleHeaderAnimation = () => {
        const top = window.scrollY;
        if (top > 10) {
            $(`.${style.header}`).addClass(`${style.fixedHeader}`);
        } else {
            $(`.${style.header}`).removeClass(`${style.fixedHeader}`);
        }
    }

    const gotoProfile = () => {
        deleteCookie("newUser")
        setPopup(false);
        window.location.href = "/profile"
    }

    return <>
        <div className={`${style.content}`}>
            <Outlet context={{ height: dimesions.height, winWidth: dimesions.winWidth, winHeight: dimesions.winHeight }} />
        </div>
        {showPopup === "welcome-popup" && (
            <>
                <DialogBox onClose={() => {
                    deleteCookie("newUser")
                    setPopup(false)
                }} content={(onCloseHandler) => {
                    return (
                        <div className={style.welcomePopup}>
                            <div className={style.inner_wrap}>
                                <div className={style.text}>
                                    <h4>Welcome {get(data, 'first_name', '')},</h4>
                                    <div className={style.description}>
                                        <p>We're thrilled to have you join our community of history enthusiasts. As a member, you now have exclusive access to a treasure trove of historical talks from the renowned Chalke Festival, spanning a wide range of fascinating topics. Dive into our extensive library of content to deepen your understanding and appreciation of history.</p>
                                        <p>By joining the Chalke History Club, you're not just exploring the past; you're also playing a vital role in shaping the future. Your support helps further our Charitable Purpose of inspiring children to discover the wonders of history, ensuring that the next generation is as passionate about the past as we are.</p>
                                        <p>Thank you for being a part of our mission. Enjoy your journey through history with us!</p>
                                    </div>
                                    <div className={style.button_wrap}>
                                        <Button onClick={onCloseHandler} label="LETS BEGIN!" />
                                        <Button onClick={gotoProfile} label="COMPLETE YOUR PROFILE" color="invert" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}></DialogBox>
            </>
        )}
        <GtagManager url={pathname} />
    </>
}

export default DarkGuest;