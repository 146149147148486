import React, { useState } from 'react';
import { get, isEmpty, isString } from 'lodash';

import Spinner from '../Spinner';
import Video from './Video';
import OutOfView from '../Lazyload/OutOfView';
import { generateUrl } from './Url';

import style from './style.module.css';
import SVG from './Svg';

const BrokenImage = ({ className, icon, sizer }) => {
    return (
        <>
            {
                sizer === true && (
                    <div className={style.sizer}></div>
                )
            }
            <div className={style.bsz}>
                <div className={`${style.bgImage}${className ? " " + className : ""}`}>
                    <div className={style.fa}>
                        <i className={`fa-solid ${icon}`}></i>
                    </div>
                </div>
            </div>
        </>
    )
}

const Gradient = ({ code }) => {
    return <div className={style.gradientBar} style={{ background: code }}></div>
}

const NormalImage = ({ src, className, alt, gradient, clipPath }) => {
    return <>
        {
            gradient && <Gradient code={gradient} />
        }
        <div className={style.bsz}>
            <div className={`${style.bgImage}${className ? " " + className : ""}`} style={{ backgroundImage: `url(${src})`, clipPath: `url(#${clipPath})` }}>
                <img src={src} alt={alt} />
            </div>
        </div>
    </>
}

const LazyImage = ({ src, className, alt, sizer, gradient, clipPath }) => {

    const [loaded, setLoaded] = useState(0);
    const [dimesion, setDimension] = useState([0, 0]);

    const onOutHandler = (entry, observer) => {
        if (entry.isIntersecting && loaded === 0) {
            //Create an image 
            let img = new Image();
            img.src = src;
            //Check for load status
            if (img.complete || img.readystate === 4) {
                setLoaded(1);
                setDimension([img.width, img.height]);
            }
            else {
                img.onload = () => {
                    setLoaded(1);
                    setDimension([img.width, img.height]);
                }
                img.onerror = () => {
                    setLoaded(2);
                }
            }
            observer.disconnect();
        }
    }

    return (
        <>
            {
                sizer === true && (
                    <div className={style.sizer} style={{ paddingTop: `${dimesion[0] > 0 && dimesion[1] > 0 ? dimesion[1] * 100 / dimesion[0] : 50}%` }}></div>
                )
            }
            {
                gradient && <Gradient code={gradient} />
            }
            <OutOfView className={style.bsz} onOutCallback={onOutHandler}>
                <div className={`${style.bgImage}${className ? " " + className : ""}${loaded === 0 ? " " + style.loading : ""}`} style={{ backgroundImage: loaded === 1 ? `url("${encodeURI(src)}")` : "none", clipPath: `url(#${clipPath})` }}>
                    {
                        loaded === 1 && (
                            <img src={src} alt={alt} />
                        )
                    }
                    {
                        loaded === 0 && (
                            <div className={style.spinner}>
                                <Spinner color="#fff" />
                            </div>
                        )
                    }
                    {
                        loaded === 2 && (
                            <div className={style.fa}>
                                <i className={`fa-regular fa-image-slash`}></i>
                            </div>
                        )
                    }
                </div>
            </OutOfView>
        </>
    )
}

const Media = ({ src, size = false, className = false, alt = "", lazy = true, clipPath = "", logoImage = false, fallback = true, retainAspectRatio = false, svg = false, gradient = false, brokenIcon = "fa-image" }) => {
    //If src is empty then display a broken image
    if (isEmpty(src)) {
        if (fallback === true) {
            //Show a broken image for fallback if set to true
            return (
                <BrokenImage icon={brokenIcon} sizer={retainAspectRatio} />
            )
        }
        return null;
    }

    if (svg) {
        return <SVG src={generateUrl(src)} />
    }

    //Media type
    const type = get(src, "type", "image");

    //Validate size
    if (type === "image" && !isString(src) && (size === false || (/{\d+}x{\d+}/g).test(size))) {
        alert("Enter sizer information for the image. Size is a required field.");
    }

    //Construct image url
    let media_url = generateUrl(src, size);

    if (type === "image") {
        //If lazy load is disabled, then display the image directly
        if (logoImage) {
            return <img src={media_url} alt={alt} />
        }
        if (lazy === false && retainAspectRatio === false) {
            return (
                <NormalImage gradient={gradient} src={media_url} className={className} alt={alt} clipPath={clipPath} />
            )
        }
        //Lazy load the image
        return (
            <LazyImage gradient={gradient} src={media_url} className={className} alt={alt} sizer={retainAspectRatio} clipPath={clipPath} />
        )
    }

    if (type === "video") {
        return (
            <Video src={media_url} className={className} />
        )
    }

    return null;
}

export default Media;