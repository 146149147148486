import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery-slim';
import { getUser, saveToken } from '../../actions';
import { getCookie } from '../../helpers/storage';

const PrivateLight = () => {
    const dispatch = useDispatch();
    const token = getCookie();
    const { user: { fetched = false } } = useSelector((state) => state.storage);
    useEffect(() => {
        // handling theme class
        $("html").removeClass(`darkTheme`).addClass(`lightTheme`);

        // Fetching user profile
        if (!fetched && saveToken(getCookie())) {
            dispatch(getUser());
        }
    }, []);

    if (token)
        return <>
            <Outlet />
        </>
    return <Navigate to={'/'} />
}

export default PrivateLight;