import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Chevron } from "../../helpers/Icons";
import Link from "../Link";
import SVG from "../Media/Svg";
import style from "./style.module.css";

const FilterBox = ({ meta: { error, touched }, input: { name, onChange, value = '' }, placeholder = "", options = [] }) => {
    const [toggleOption, setOption] = useState(false);
    const filterRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", clickListener);
        return (() => {
            document.removeEventListener("click", clickListener)
        });
    })

    const clickListener = (e) => {
        setOption(filterRef.current.contains(e.target));
    };

    const onChangeHandler = (value) => {
        setOption(false);
        onChange(value);
    }

    return <div ref={filterRef} key={`filterbox_${name}`} id={`filterbox_${name}`} className={`${toggleOption ? `${style.toggled} ` : ''}${style.filterBox}`}>
        <div className={style.label}>
            <label dangerouslySetInnerHTML={{ __html: value !== '' ? get(value, "label", placeholder) : placeholder }} />
            <SVG className={toggleOption ? style.rotate : ''} src={Chevron} />
        </div>
        {
            toggleOption && <div className={style.options}>
                {
                    options.map((option, index) => {
                        return <Link key={`option_${index}`} className={style.optionItem} onClick={() => { onChangeHandler(option) }}>
                            <span dangerouslySetInnerHTML={{__html:option.label}}/>
                        </Link>
                    })
                }
            </div>
        }
    </div>
}

export default FilterBox;