import React, { Suspense, lazy } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './assets/css/fonts.css';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

import LightLayout from './pages/Layouts/Light';
import PrivateLightLayout from './pages/Layouts/PrivateLight';
import DarkGuestLayout from './pages/Layouts/DarkGuest';
import PrivateDark from './pages/Layouts/PrivateDark';
import Admin from './pages/Layouts/Admin';
import Header from './pages/Layouts/Header';
import Footer from './pages/Layouts/Footer';

const Benefits = lazy(() => import('./pages/MemberShipBenefits'))
const Forgot = lazy(() => import('./pages/Guest/Login/Forgot'));
const Reset = lazy(() => import('./pages/Guest/Login/Reset'));
const NoMatch = lazy(() => import('./pages/Guest/NoMatch/index'));
const Register = lazy(() => import('./pages/Guest/Register'));
const Articles = lazy(() => import('./pages/Articles'));
const Preview = lazy(() => import('./pages/Preview'));
const Page = lazy(() => import('./pages/Page'));
const Profile = lazy(() => import('./pages/Profile'));
const ChangePaymentMethod = lazy(() => import('./pages/ChangePayment'));
const RenewMembership = lazy(() => import('./pages/Profile/RenewMembership'));
const Unauthorize = lazy(() => import('./pages/Guest/Unauthorize'));

const SuspendPage = (element, fallback = <div />) => {
    return (
        <Suspense fallback={fallback}>
            {element}
        </Suspense>
    )
}

const App = () => {
    return (
        <BrowserRouter basename={process.env.REACT_APP_URL_PREFIX}>
            <Header />
            <Routes>
                {/* Guest Pages */}
                <Route element={<DarkGuestLayout />}>
                    <Route path={`/forgot-password`} element={SuspendPage(<Forgot />)} />
                    <Route path={`/reset-password/:hash`} element={SuspendPage(<Reset />)} />
                    <Route path={`/profile`} element={SuspendPage(<Profile />)} />
                    <Route path={`/change-payment-method`} element={SuspendPage(<ChangePaymentMethod />)} />
                    <Route path={`/renew-membership`} element={SuspendPage(<RenewMembership />)} />
                    <Route path={`/benefits`} element={SuspendPage(<Benefits />)} />
                    <Route path={`/articles`} element={SuspendPage(<Articles />)} />
                    <Route path={`/articles/:articleURL`} element={SuspendPage(<Articles />)} />
                    <Route path="/:pageName" element={SuspendPage(<Page />)} />
                    <Route path="/:rootPage/:pageName" element={SuspendPage(<Page />)} />
                    <Route path={`/account-not-exist`} element={SuspendPage(<Unauthorize />)} />
                    <Route path="*" element={SuspendPage(<Page />)} />
                </Route>
                {/* Registration */}
                <Route element={<LightLayout />}>
                    <Route path={`/register`} element={SuspendPage(<Register />)} />
                    <Route path={`/register/:membeshipId`} element={SuspendPage(<Register />)} />
                </Route>
                <Route element={<PrivateLightLayout />}>
                    <Route path="*" element={SuspendPage(<NoMatch />)} />
                </Route>
                <Route element={<Admin />}>
                    <Route path={`/preview/:previewId`} element={SuspendPage(<Preview />)} />
                </Route>
            </Routes>
            <Footer />
            <ToastContainer />
        </BrowserRouter>
    )
}

export default App;