import { useEffect } from 'react';
import { trackGTMEvent } from '../helpers/google-tag';

export default function GtagManager({ url }) {
    useEffect(() => {
        if (window.Termly && window.Termly.getConsentState() && window.Termly.getConsentState().analytics) {
            trackGTMEvent(url);
        }
    }, [url]);

    return null;
}