import React, { useEffect, useRef, useState } from "react";

import SVG from "../../Media/Svg";
import { BackArrow } from "../../../helpers/Icons";
import SearchIcon from "../../../assets/icons/search2.svg";

import style from "./style.module.css";

export default function SearchDropDown(props) {

    const { className, input: { name, onChange, value }, list = [], label = null, placeholder = "Select", meta: { error, touched }, } = props;

    const [isOpen, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return (() => {
            document.removeEventListener('click', handleOutsideClick);
        });
    }, [containerRef]);

    useEffect(() => {
        if (JSON.stringify(list) !== JSON.stringify(options)) {
            setOptions(list);
        }
    }, [list]);

    const onChangeHandler = (e, value) => {
        e.preventDefault();
        if (value) {
            onChange(value);
            toggle();
        }
    }

    const handleFilter = ({ target: { value = "" } }) => {
        const filterOption = list.filter((item) => {
            return item.toLowerCase().includes(value.toLowerCase())
        });
        setOptions(filterOption.length > 0 ? filterOption : ['No Options Found']);
    }

    const toggle = (e) => {
        if (e) {
            e.preventDefault();
        }
        setOpen(!isOpen);
        setOptions(list);
    }

    const handleOutsideClick = (e) => {
        if (containerRef.current && containerRef.current.contains(e.target)) {
            return false;
        }
        setOptions(list);
        setOpen(false);
    }

    return (
        <div ref={containerRef} className={`${style.formGroup}${className ? ` ${className}` : ""}${value !== "" ? ` ${style.hasValue}` : ""}`} key={`dropdown_${name}`} onClick={(e) => e.stopPropagation()}>
            {
                label !== false && (
                    <div className={`${style.controlLabel}`}>
                        <span>{label}</span>
                    </div>
                )
            }
            {
                <button className={`${style.controlElement} ${`${name}_toggle_button`}${isOpen ? ` ${style.opened}` : ""}`} onClick={toggle}>
                    <label dangerouslySetInnerHTML={{ __html: value !== '' ? value : placeholder }} />
                    <SVG className={`${style.icon}${isOpen ? ` ${style.opened}` : ""}`} src={BackArrow} />
                </button>
            }
            {
                (
                    isOpen && options.length > 0 && <div className={style.optionContainer}>
                        <div className={style.optionWrapper}>
                            <div className={`${style.searchBox}`}>
                                <SVG wrapper="span" src={SearchIcon} className={style.searchIcon} />
                                <input className={`${style.searchInput} ${name}-search-field`} placeholder="Type to search" type="text" onChange={handleFilter} />
                            </div>
                            <div className={style.options}>
                                {
                                    options.map((option, index) => {
                                        return <button onClick={(e) => { onChangeHandler(e, option) }} className={style.item} key={`options_${name}_${index}`} dangerouslySetInnerHTML={{ __html: option }} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </div>
    )
}