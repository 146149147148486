import React from 'react';
import { Link as Anchor } from "react-router-dom";

export default class Link extends React.Component {
    render() {
        const { children = "", to = "", className = false, onClick = () => { }, disabled = false, download = false } = this.props;
        let { target = "_self" } = this.props;
        let mailtoCondt = (to.indexOf("mailto:") !== -1);
        let telCondt = (to.indexOf("tel:") !== -1);
        let httpCondt = (to.indexOf("http") !== -1);
        if (httpCondt) {
            target = to.toLocaleLowerCase().includes('podiumsso') ? "_self" : "_blank"
        }

        if (disabled) {
            return (
                <div className={`${className}`}>
                    {children}
                </div>
            )
        }

        if (download === true) {
            return (
                <a className={`${className}`} href={to} onClick={onClick} download>
                    {children}
                </a>
            )
        }

        if (httpCondt) {
            return (
                <a className={`${className}`} href={to} target={target} rel="noopener noreferrer" onClick={onClick}>
                    {children}
                </a>
            );
        }

        if (mailtoCondt || telCondt) {
            return (
                <a className={`${className}`} href={to} onClick={onClick}>
                    {children}
                </a>
            );
        }

        if (to) {
            return (
                <Anchor className={`${className}`} to={to} target={target} onClick={onClick}>
                    {children}
                </Anchor>
            )
        }

        return (
            <button type="button" className={`${className}`} onClick={onClick}>
                {children}
            </button>
        );
    }
}