import React from 'react';
import { displaySelectValue } from "../../helpers/input";
import style from "./style.module.css";

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.input.value || "",
            prompt: props.prompt || "Please select..."
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (prevProps.input.value !== value && value !== this.state.value) {
            this.setState({
                value: value
            })
        }
    }

    onChangeHandler = (e) => {
        let value = e.target.value;
        this.props.input.onChange(value);
        this.setState({
            value
        })
    }

    render() {
        const { label, meta: { touched, error }, list = [], required = false, disabled = false, boldLabel = false, upperCaseLabel = false, fieldClass = false, className = "" } = this.props;
        const { prompt, value } = this.state;
        return (
            <div className={`${style.formGroup}${disabled === true ? " " + style.selectDisabled : ""}${boldLabel ? ` ${style.boldLabel}` : ''}${className ? ` ${className}` : ""}${touched && error ? " " + style.inputError : ""}`}>
                {
                    label !== false && (
                        <div className={`${style.controlLabel} ${upperCaseLabel ? `${style.upperCaseLabel}` : ''}`}>
                            <span>{label}</span>
                            {
                                required && (
                                    <span className={style.required}>*</span>
                                )
                            }
                        </div>
                    )
                }
                <div className={`${style.formField}${fieldClass ? ` ${fieldClass}` : ''} ${style.textbox} ${style.selectBox}`}>
                    <span className={style.fa}>
                        <i className="fa-light fa-angle-down"></i>
                    </span>
                    <span>{displaySelectValue(this.state, this.props)}</span>
                    {
                        disabled === false && (
                            <select value={value} onChange={(e) => { this.onChangeHandler(e) }}>
                                <option value="">{prompt}</option>
                                {
                                    list.map((item, i) => {
                                        return (
                                            <option value={item.id} key={`${i}_${item.id}`}>{item.text}{item.additionalText ? (<span>&nbsp;{item.additionalText}</span>) : ''}</option>
                                        )
                                    })
                                }
                            </select>
                        )
                    }
                    {touched && error && <p className={`${style.helpBlock}`}>{error}</p>}
                </div>
            </div>
        )
    }
}