import React from 'react';
import { cleanInput } from "../../helpers/input";

export default class Hidden extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: cleanInput(props.input.value)
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (value !== prevProps.input.value && value) {
            this.setState({
                value: cleanInput(value)
            })
        }
    }

    render() {
        const { input } = this.props;
        return (
            <input type={"hidden"} {...input} />
        )
    }
}