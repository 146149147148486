import React from 'react';
import { orderBy } from 'lodash';
import Checkbox from "./Checkbox";
import Other from "./Other";
import Button from "../Button";
import style from "../style.module.css";

export default class DropdownMultiple extends React.Component {
    constructor(props) {
        super(props);
        let valueArr = JSON.parse(props.input.value || "[]");
        this.state = {
            value: props.input.value || "",
            prompt: props.prompt || "Click to select...",
            valueArr,
            opened: false
        }
        this.timer = 0;
    }

    componentDidMount() {
        //Setup click outsidebox event
        document.addEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (prevProps.input.value !== value && value !== this.state.value) {
            this.setState({
                value: value,
                valueArr: JSON.parse(value || "[]")
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.node.contains(e.target)) {
            return false;
        }
        this.closeBoxHandler();
    }

    openBoxHandler = () => {
        this.setState({ opened: true })
    }

    closeBoxHandler = () => {
        this.setState({ opened: false })
    }

    openToggle = () => {
        this.setState({ opened: !this.state.opened })
    }

    onChangeHandler = (checked, item) => {
        const { valueArr } = this.state;
        let newValueArr = valueArr;
        const valueIndex = valueArr && valueArr.findIndex(i => i.id === item.id);
        if (checked) {
            if (valueIndex === -1) {
                newValueArr = [...valueArr, item];
            }
        }
        else {
            if (valueIndex > -1) {
                newValueArr.splice(valueIndex, 1);
            }
        }
        this.triggerChange(newValueArr);
    }

    onRemoveHandler = (id) => {
        let newValueArr = this.state.valueArr;
        const valueIndex = newValueArr.findIndex(item => id === item.id);
        if (valueIndex > -1) {
            newValueArr.splice(valueIndex, 1);
        }
        this.triggerChange(newValueArr);
    }

    onAllHandler = (checked) => {
        const { list } = this.props;
        let newValueArr = [];
        if (checked) {
            list.map((item) => {
                newValueArr = [...newValueArr, item];
                return item;
            })
        }
        this.triggerChange(newValueArr);
    }

    triggerChange = (arr) => {
        const value = arr.length === 0 ? "" : JSON.stringify(arr);
        this.props.input.onChange(value);
        this.setState({
            value,
            valueArr: arr
        })
    }

    onCancelHandler = () => {
        this.triggerChange([]);
        this.closeBoxHandler();
    }

    isAllChecked = () => {
        const { list } = this.props;
        const { valueArr } = this.state;
        return JSON.stringify(orderBy(list, "id")) === JSON.stringify(orderBy(valueArr, "id"))
    }

    otherValue = (value) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            let newValueArr = this.state.valueArr;
            const valueIndex = newValueArr.findIndex(item => "Other" === item.id);
            if (valueIndex > -1) {
                newValueArr[valueIndex].value = value || "Other";
            }
            this.triggerChange(newValueArr);
        }, 100);
    }

    render() {
        const { label, meta: { touched, error }, list = [], required = false, input, allOption = true, noneOption = false, otherOption = true, className = false, boldLabel = false, upperCaseLabel = false, tagClassName = "" } = this.props;
        const { name } = input;
        const { prompt, valueArr, opened } = this.state;
        const other = otherOption === true && valueArr && typeof valueArr !== 'string' && valueArr.length > 0 && valueArr.find(v => v.id === "Other") ? true : false;
        const none = noneOption === true && valueArr && typeof valueArr !== 'string' && valueArr.length > 0 && valueArr.find(v => v.id === "None") ? true : false;

        return (
            <div ref={node => this.node = node} className={`${style.formGroup}${boldLabel ? ` ${style.boldLabel}` : ''}`} onClick={(e) => e.stopPropagation()}>
                {
                    label !== false && (
                        <div className={`${style.controlLabel}  ${upperCaseLabel ? `${style.upperCaseLabel}` : ''}`}>
                            <span dangerouslySetInnerHTML={{ __html: label }}></span>
                            {required && <span className={style.required}>*</span>}
                        </div>
                    )
                }
                <div className={`${style.selectMultipleBox}`}>
                    <div className={`${style.formField}${className ? ` ${className}` : ''} ${style.textbox}${touched && error ? " " + style.inputError : ""}`}>
                        <span className={`${style.fa}${opened === true ? ` ${style.rotate}` : ''}`}>
                            <i className="fa-light fa-angle-down"></i>
                        </span>
                        <button type="button" className={style.selectMultipleBoxTrigger} onClick={this.openToggle}></button>
                        {
                            valueArr && typeof valueArr !== 'string' && valueArr.length === 0 && (
                                <div className={style.selectMultipleBoxPrompt}>{prompt}</div>
                            )
                        }
                        {
                            valueArr && typeof valueArr !== 'string' && valueArr.length > 0 && (
                                <div className={style.selectMultipleBoxOptions}>
                                    {
                                        valueArr.map((values) => {
                                            return (
                                                <button type="button" onClick={() => this.onRemoveHandler(values.id)} className={`${style.selectMultipleBoxOption} ${tagClassName}`} key={`${name}_dropdown_multi_option_${values.id}`}>
                                                    <span>{values.value}</span>
                                                    <span className={style.selectMultipleBoxOptionFa}>
                                                        <i className='fa-light fa-times'></i>
                                                    </span>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                    {
                        opened === true && (
                            <div className={`${style.formField} ${style.selectMultipleBoxList}`}>
                                <div className={`${style.selectMultipleBoxListScroller}`}>
                                    {
                                        allOption === true && (
                                            <Checkbox id={`${name}_dropdown_multi_all`} checked={this.isAllChecked()} item={{ id: "All", value: "All" }} onChange={this.onAllHandler} />
                                        )
                                    }
                                    {
                                        list.map((item, i) => {
                                            let id = `${name}_dropdown_multi_${i}`;
                                            return (
                                                <Checkbox key={id} id={id} checked={valueArr && typeof valueArr !== 'string' && valueArr.find(v => v.id === item.id) ? true : false} item={item} onChange={this.onChangeHandler} />
                                            )
                                        })
                                    }
                                    {
                                        noneOption === true && (
                                            <Checkbox id={`${name}_dropdown_multi_none`} checked={none} item={{ id: "None", value: "None" }} onChange={this.onChangeHandler} />
                                        )
                                    }
                                    {
                                        otherOption === true && (
                                            <Checkbox id={`${name}_dropdown_multi_other`} checked={other} item={{ id: "Other", value: "Other" }} onChange={this.onChangeHandler} />
                                        )
                                    }
                                </div>
                                {
                                    other === true && (
                                        <Other input={input} onValueChange={this.otherValue} />
                                    )
                                }
                                <div className={`${style.textbox} ${style.selectMultipleBoxButtons}`}>
                                    <Button className={style.cancelBtn} color="invert" onClick={this.onCancelHandler} label={`Cancel`} />
                                    <Button className={style.saveBtn} onClick={this.closeBoxHandler} label={`Save`} />
                                </div>
                            </div>
                        )
                    }
                    {touched && error && <p className={`${style.helpBlock}`}>{error}</p>}
                </div>
            </div>
        )
    }
}