import React, { useRef, useState } from 'react';
import OutOfView from '../Lazyload/OutOfView';
import style from './style.module.css';

const Video = ({ src, className }) => {
    const ref = useRef(null);
    const [loaded, setLoaded] = useState(false);

    const onOutHandler = (entry) => {
        if (loaded === true) {
            if (entry.isIntersecting) {
                ref.current.play();
            }
            else {
                ref.current.pause();
            }
        }
    }

    return (
        <OutOfView className={`${style.videoWrap}${className ? " " + className : ""}`} onOutCallback={onOutHandler}>
            <video ref={ref} className={style.video} onLoadedData={() => setLoaded(true)} muted autoPlay loop playsInline poster="data:image/gif,AAAA">
                <source src={src} type="video/mp4" />
            </video>
            <div className={style.bsz}></div>
        </OutOfView>
    )
}

export default Video;