import React, { Component } from 'react';
import { get } from "lodash";

import style from '../style.module.css';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked
        }
    }

    componentDidUpdate(prevProps) {
        const { checked } = this.props;
        if (get(prevProps, "checked") !== checked) {
            this.setState({
                checked
            })
        }
    }

    render() {
        const { id, item } = this.props;
        const { checked } = this.state;
        return (
            <label htmlFor={id} className={`${style.checkbox}`}>
                <input
                    type="checkbox"
                    onChange={this.onChangeHandler}
                    checked={checked}
                    className={style.checkboxInput}
                    id={id}
                />
                <div className={`${style.checkboxSquare}`}></div>
                <div className={`${style.checkboxText}`}>{item.value}</div>
            </label>
        )
    }

    onChangeHandler = () => {
        const { onChange, item } = this.props;
        const { checked } = this.state;
        onChange(!checked, item);
        this.setState({
            checked: !checked
        })
    }
}