import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Logo from './Logo';
import Link from '../../components/Link';
import Center from '../../components/Center';
import Media from '../../components/Media';


import { getHeaderFooter } from '../../actions';

import style from './style.module.css';
import PlanChecker from './PlanChecker';

const Footer = () => {
    const dispatch = useDispatch();
    const { pathname = "" } = useLocation();
    const { token } = useSelector(state => state.storage);

    useEffect(() => {
        dispatch(getHeaderFooter());
    }, [token]);

    const { data = {}, fetching = false } = useSelector((state) => get(state, 'content.config', {}));

    if (pathname === "/authorize") {
        return null
    }

    if (fetching || !get(data, 'data.footer')) {
        return null;
    }
    const { address = "", bg_image, brand_greeting_text, brand_image = [], social_links = [], footer_public_links = [], footer_private_links = [], footer_logo_dark } = get(data, 'data.footer', {});
    const campaignPages = [`/${process.env.REACT_APP_CAMPAIGN_URL}`, `/${process.env.REACT_APP_PODIUM_CAMPAIGN_URL}`];
    const isCampaign = (campaignPages.indexOf(pathname) !== -1) ? false : false;
    const links = token ? footer_private_links : footer_public_links;

    const icons = {
        "instagram": "fa-brands fa-instagram",
        "twitter": "fa-brands fa-x-twitter",
        "linkedin": "fa-brands fa-linkedin",
        "facebook": "fa-brands fa-facebook",
        "youtube": "fa-brands fa-youtube",
        "tiktok": "fa-brands fa-tiktok"
    }
    return (
        <div className={`${pathname.includes("/register") ? `${style.register} ` : ''} ${style.footer}${isCampaign === true ? ` ${style.campaignPage}` : ""}`}>
            <Media src={bg_image} lazy={false} size="original" className={style.bg_image} />
            <Center>
                <div className={style.inner_wrap}>
                    <div className={style.footer_logo}>
                        <Logo alt="Chalke" size="original" light={footer_logo_dark} dark={footer_logo_dark} />
                    </div>
                    <div className={style.footerLeft}>
                        {
                            links.map((item, i) => {
                                if (campaignPages.indexOf(pathname) !== -1 && get(item, 'title', '').toLowerCase() === 'sitemap') {
                                    return null;
                                } else {
                                    return (
                                        <div className={`${style.footerCol} ${i === links.length - 1 ? style.flex_wrap : ''}`} key={`f_title_${i}`}>
                                            <div>
                                                <div className={style.footerTitle}>{item.title}</div>
                                                {
                                                    item.links.map((linkItem, j) => {
                                                        if (get(linkItem, "url") === "/cookie-preferences") {
                                                            return (
                                                                <div className={style.footerLinkItem} key={`f_title_${i}_${j}`}>
                                                                    <Link onClick={() => window.displayPreferenceModal()}>{linkItem.label}</Link>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className={style.footerLinkItem} key={`f_title_${i}_${j}`}>
                                                                    <Link target={get(linkItem, "target", "_self")} to={get(linkItem, "url", '#')}>{linkItem.label}</Link>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            {
                                                i === links.length - 1 && (
                                                    <div className={style.social_link_wrap}>
                                                        {social_links.length > 0 && social_links.map((item, i) => {
                                                            return (
                                                                <div className={style.socialItem} key={`f_s_${i}`}>
                                                                    <Link to={get(item, 'link')}>
                                                                        <i className={icons[get(item, 'icon')]}></i>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className={`${style.footerRight}`}>
                        <div>
                            {brand_greeting_text && (
                                <p className={style.greet_text} dangerouslySetInnerHTML={{ __html: brand_greeting_text }}></p>
                            )}
                            <div className={style.brand_image_wrap}>
                                {brand_image.length > 0 && brand_image.map((brand, key) => {
                                    return (
                                        <Link target="_blank" to={brand.brand_link} className={style.brand_image} key={key}>
                                            <Logo alt='Chalke' size="original" light={brand.brand_logo} dark={brand.brand_logo} lazy={false} />
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                        {
                            address && (
                                <p className={style.address} dangerouslySetInnerHTML={{ __html: address }}></p>
                            )
                        }
                    </div>
                </div>
            </Center>
            <PlanChecker />
        </div>
    )
}

export default Footer;