import { get } from "lodash";
import api from "./api";
import adminApi from "./adminapi";
import regsiterApi from "./registerapi";

const urls = {
  apply_promo: "/discounts/coupon",
  contact: "/submit/contact",
  register_complete: "/register-complete",
  register: "/register-user",
  verify_otp: "/otp-verify",
  resend_otp: "/otp-resend",
  login: "/login",
  forgot: "/forgot",
  reset: "/profile/reset-password",
  verifyHash: "/profile/verify-hash",
  config: "/config",
  pageInfo: "page",
  rate: "actions/rating",
  save: "actions/save",
  search: "articles",
  saveList: "saved-list",
  watchList: "watch-history",
  latestEdition: "latest-editions",
}

const getDispatchApi = (attribute) => {
  let dispatchApi = api;
  if (["articlecarousel", "features/list", "articles", "latestEdition", "config", "features/themes", "search"].includes(attribute)) {
    dispatchApi = regsiterApi;
  }
  return dispatchApi;
}

function generateYiiURL(attr) {
  const urls = {
    articlecarousel: "/articles/info",
    articles: "articles/",
    "features/list": "/features/list",
    "features/themes": "features/themes",
    latestEdition: "/latest-edition",
    config: "/page/config",
    changeplanpayment: "/membership/confirm-payment",
    renewmembership: "/membership/renew-membership",
    changePayment: "/payment/method-change",
    "search": "articles/"
  }
  return get(urls, attr, attr);
}

function generateURL(attr, yiiApi = false, data = {}) {
  if (attr === 'changeplanpayment') {
    return `${generateYiiURL(attr)}/${get(data, 'id')}`;
  }
  if (attr === 'changePayment') {
    return `${generateYiiURL(attr)}`;
  }
  if (["articlecarousel", "features/list", "articles", "latestEdition", "features/themes", "config", "search", "renewmembership"].includes(attr)) {
    return generateYiiURL(attr);
  }

  if (attr === 'register_complete') {
    return `submit${get(urls, attr, attr)}`;
  }
  else if (['register', 'resend_otp', 'verify_otp'].includes(attr)) {
    return `${get(urls, attr, attr)}`;
  }
  if (yiiApi) {
    return `${get(urls, attr, attr)}`;
  }
  return `${get(urls, attr, attr)}/`;
}

export function nextSubmit(data) {
  return dispatch => {
    dispatch({
      type: "FORM_CONTINUE",
      payload: data
    });
  };
}

export function paymentIntent(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: "PAY",
      payload: regsiterApi.post('/payment-intents', data),
      meta: { attribute, formData: data }
    })
  }
}

export function ResetPaymentIntent(data) {
  return (dispatch) => {
    dispatch({
      type: "PAY_RESET",
      payload: { data: {}, status: 201 },
      meta: { attribute: "intent", formData: data }
    })
  }
}
export function paymentConfirmation(data) {
  return (dispatch) => {
    dispatch({
      type: "PAY",
      payload: regsiterApi.post('/confirm-payment', data),
      meta: { attribute: "confirmation", formData: data }
    })
  }
}

export function applyPromo(data) {
  let url = generateURL("apply_promo");
  return (dispatch) => {
    dispatch({
      type: "APPLY_COUPON",
      payload: api.post(url, data),
      meta: { attribute: "apply_promo", formData: data }
    })
  }
}

export function RegisterForm(attribute, data, patch = false) {
  let url = generateURL(attribute);
  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: patch ? regsiterApi.patch(url, data) : regsiterApi.post(url, data),
      meta: { attribute, formData: data }
    })
  }
}

export function yiiApiSubmitFormByPost(attribute, data) {
  let url = generateURL(attribute, true, data)
  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: regsiterApi.post(url, data),
      meta: { attribute, formData: data }
    });
  };
}

export function yiiApiSubmitForm(attribute, data, patch = false, put = false) {
  let url = generateURL(attribute, true)

  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: put ? regsiterApi.put(url, data) : patch ? regsiterApi.patch(url, data) : regsiterApi.post(url, data),
      meta: { attribute, formData: data }
    });
  };
}

export function submitForm(attribute, data, patch = false) {
  let url = generateURL(attribute)

  if (['register', 'resend_otp', 'verify_otp'].includes(attribute)) {
    return (dispatch) => {
      dispatch({
        type: "SUBMIT",
        payload: patch ? regsiterApi.patch(url, data) : regsiterApi.post(url, data),
        meta: { attribute, formData: data }
      });
    }
  }

  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: patch ? api.patch(url, data) : api.post(url, data),
      meta: { attribute, formData: data }
    });
  };
}
export function saveToken(data, userType, remember = true) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_TOKEN",
      payload: { data, userType },
      meta: { remember: remember }
    });
  };
}

export function saveUser(data) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_USER",
      payload: { data }
    });
  };
}

export function login(data, attribute = "login") {
  return dispatch => {
    dispatch({
      type: "LOGIN",
      payload: api.post('login/', data),
      meta: { attribute }
    })
  }
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      payload: api.patch('/logout/')
    });
  };
}
export function getUser() {
  return (dispatch) => {
    dispatch({
      type: 'USER',
      payload: regsiterApi.get('/profile')
    });
  };
}
export function getItem(attribute, id) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ITEM',
      payload: regsiterApi.get("/article/" + id),
      meta: { attribute }
    });
  };
}
export function getInfo(id) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ITEM_INFO',
      payload: api.get(`/articles/${id}/info/`),
      meta: { attribute: "articleInfo" }
    });
  };
}

export function getHeaderFooter() {
  const attribute = 'config';
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: getDispatchApi(attribute).get(`${generateURL(attribute)}`),
      meta: { attribute }
    });
  };
}

export function fetchRegister() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: regsiterApi.get(`/page/register`),
      meta: { attribute: "page" }
    });
  };
}

export function fetchContent(attribute) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: getDispatchApi(attribute).get(`${generateURL(attribute)}`),
      meta: { attribute }
    });
  };
}

export function getPage(url, data) {
  if (url === "/")
    url = "/home"
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONTENT',
      payload: regsiterApi.get(`/page${url}`, data),
      meta: { attribute: "page" }
    });
  };
}

export function getContent(url, data, attribute = "page") {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONTENT',
      payload: api.get(`/${attribute}${url}/`, data),
      meta: { attribute }
    });
  };
}

export function contentInfo(data, { attribute = "articles", url = 'info' }) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONTENT',
      payload: getDispatchApi(attribute).post(`/${generateURL(attribute)}${url}`, data),
      meta: { attribute: get(data, "attr", attribute) }
    });
  };
}

export function getWidgetData(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: "WIDGET",
      payload: getDispatchApi(attribute).post(`${generateURL(attribute)}`, data),
      meta: { attribute }
    });
  };
}

export function fetchAdminData(attribute, id, data = {}) {
  return dispatch => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: adminApi.get(`/admin/${attribute}/${id}/`, data),
      meta: { attribute }
    })
  }
}

export function submitReset(attribute) {
  return (dispatch) => {
    dispatch({
      type: 'SUBMIT_RESET',
      payload: {},
      meta: { attribute }
    });
  };
}

export function deleteProfile(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: 'SUBMIT',
      payload: api.delete(`/profile/?deleteOption=${data}`),
      meta: { attribute }
    });
  };
}

export function createMedia(attribute, data) {
  return dispatch => {
    dispatch({
      type: 'SUBMIT',
      payload: api.post('/media/create/', data),
      meta: { attribute }
    })
  }
}

export function setSearch(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'TOGGLE_SEARCH',
    })
  }
}

export function setRegister(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'REGISTER_FORM',
    })
  }
}

export function assignError(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'ASSIGN_ERROR',
    })
  }
}

export function resetState() {
  return dispatch => {
    dispatch({
      type: "RESET_STATE"
    })
  }
}

/** Campaign actions begin */
export function campaignSignup(data) {
  return dispatch => {
    dispatch({
      type: "SUBMIT",
      payload: api.post(`/campaign/sign-up/`, data),
      meta: { attribute: 'campaign', formData: data }
    })
  }
}
/** Campaign actions end */

export function loginYii(data, attribute = "login") {
  return dispatch => {
    dispatch({
      type: "LOGIN",
      payload: regsiterApi.post('/login', data),
      meta: { attribute }
    })
  }
}