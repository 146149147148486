import { get } from "lodash";

let defaultState = {
    data: {},
    status: false,
    fetching: false,
    serverError: [],
}

export default function widgetReducer(state = {
    articlecarousel: defaultState,
}, action) {
    switch (action.type) {
        case "WIDGET_PENDING": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    fetching: true,
                }
            }
        }
        case "WIDGET_REJECTED": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    serverError: get(action.payload, 'response.data'),
                    status: get(action.payload, 'response.status'),
                }
            }
        }
        case "WIDGET_FULFILLED": {
            const { meta: { attribute }, payload: { data, status } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    data,
                    status,
                    serverError: [],
                }
            }
        }
        default:
            return state;
    }
}