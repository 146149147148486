import { get } from 'lodash';

let defaultState = {
    data: {},
    status: false,
    fetching: false,
    serverError: []
}

export default function itemReducer(state = {
    config: defaultState,
    page: defaultState,
    article: defaultState,
}, action) {
    switch (action.type) {
        case "FETCH_ITEM_INFO_PENDING":
        case "FETCH_ITEM_PENDING": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    fetching: true
                }
            }
        }
        case "FETCH_ITEM_INFO_REJECTED":
        case "FETCH_ITEM_REJECTED": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    serverError: get(action.payload, 'response.data'),
                    status: get(action.payload, 'response.status'),
                }
            }
        }
        case "FETCH_ITEM_INFO_FULFILLED":
        case "FETCH_ITEM_FULFILLED": {
            const { meta: { attribute } } = action;
            const { data, status } = action.payload;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    data,
                    status
                }
            }
        }
        default:
            return state;
    }
}