import React from 'react';
import { generateUrl } from '../../components/Media/Url';

const Logo = ({ dark, light, size = "320x0", alt = "Chalke History Hub Logo", onLoad = () => { } }) => {
    return (
        <>
            {dark && (
                <img src={generateUrl(dark, size)} alt={alt} data-theme="dark" onLoad={onLoad} />
            )}
            {light && (
                <img src={generateUrl(light, size)} alt={alt} data-theme="light" onLoad={onLoad} />
            )}
        </>
    )
}

export default Logo;