import { get } from "lodash";
import { setCookie } from "../helpers/storage";

let defaultState = {
    data: {},
    status: false,
    fetching: false,
    serverError: [],
    formData: {}
}

export default function paymentReducer(state = {
    confirmation: defaultState,
    intent: defaultState,
    payment: defaultState,
    apply_promo: defaultState
}, action) {
    switch (action.type) {
        case "PAY_PENDING": {
            const { meta: { attribute, formData } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    fetching: true,
                    formData
                }
            }
        }
        case "PAY_REJECTED": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    serverError: get(action.payload, 'response.data'),
                    status: get(action.payload, 'response.status'),
                    formData: get(action.payload, 'response.data')
                }
            }
        }
        case "PAY_FULFILLED": {
            const { meta: { attribute, formData }, payload: { data: { data, status } } } = action;
            if (attribute === "confirmation") {
                setCookie(1, "newUser");
            }
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    data,
                    status,
                    serverError: [],
                    formData
                }
            }
        }
        case "PAY_RESET": {
            const { meta: { attribute, formData }, } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    status: false,
                    formData,
                    serverError: {}
                }
            }
        }
        case "APPLY_COUPON_PENDING": {
            const { meta: { attribute, formData } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    fetching: true,
                    formData
                }
            }
        }
        case "APPLY_COUPON_FULFILLED": {
            const { meta: { attribute, formData }, payload: { data, status } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    data,
                    status,
                    serverError: [],
                    formData
                }
            }
        }
        case "APPLY_COUPON_REJECTED": {
            const { meta: { attribute } } = action;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    serverError: get(action.payload, 'response.data'),
                    status: get(action.payload, 'response.status'),
                    formData: get(action.payload, 'response.data')
                }
            }
        }
        default:
            return state;
    }
}