import { get } from 'lodash';
import { getCookie, deleteCookie } from '../helpers/storage';

export default function storageReducer(
	state = {
		token: getCookie() || false,
		user: {
			fetching: false,
			data: [],
			fetched: false
		},
		serverError: [],
		savedList: [],
		search: false,
		registerFormData: {}
	}, action) {
	switch (action.type) {
		case "SAVE_TOKEN": {
			const { data } = action.payload;
			return {
				...state,
				token: data
			};
		}
		case "SAVE_USER": {
			const { data } = action.payload;
			if (data) {
				return {
					...state,
					user: {
						...state.user,
						data: {
							...data,
							isPopuped: false
						}
					}
				}
			}
			return state
		}
		case "USER_PENDING":
		case "LOGIN_PENDING": {
			return {
				...state,
				user: {
					data: [],
					fetching: true,
					fetched: false
				}
			}
		}
		case "USER_FULFILLED":
			{
				const { status, data } = action.payload
				const savedList = get(data, 'data.savedList', [])
				let tmpList = []
				if (savedList && savedList.length) {
					savedList.map((sl) => {
						tmpList.push(get(sl, 'articleId'));
						return true;
					})
				}
				return {
					...state,
					user: {
						status,
						data: data.data,
						fetching: false,
						fetched: true
					},
					savedList: [...tmpList],
				}
			}
		case "LOGIN_FULFILLED": {
			const { status, data } = action.payload;
			return {
				...state,
				token: getCookie(),
				user: {
					status,
					data,
					fetching: false,
					fetched: true
				},
			}
		}
		case "LOGOUT_PENDING":
			return {
				...state,
				user: {
					...state.user,
					logout: {
						fetching: true
					}
				}
			}
		case "LOGOUT_FULFILLED": {
			const { payload } = action;
			deleteCookie("usertoken", { path: "/" });
			return {
				...state,
				user: {
					fetching: false,
					data: [],
					fetched: false,
					logout: {
						fetching: false,
						status: payload.status,
						data: payload.data
					}
				},
				token: false
			};
		}
		case "USER_REJECTED":
		case "LOGIN_REJECTED": {
			const { payload: { response: { status, data } } } = action
			return {
				...state,
				user: {
					status,
					data,
					fetching: false,
					fetched: true,
				},
				serverError: get(action.payload, 'response.data'),
			}
		}
		case "LOGOUT_REJECTED": {
			const { payload: { response: { data } } } = action
			return {
				...state,
				user: {
					...state.user,
					logout: {
						fetching: false,
						status: 200,
						...data
					}
				}
			}
		}
		case "SUBMIT_FULFILLED": {
			const { meta: { attribute }, payload: { data, status } } = action;
			let savedList = state.savedList;
			if (attribute === "save" & status === 200 && get(data, "id")) {
				if (get(data, "status") === true) {
					savedList.push(get(data, "id"));
				}
				if (get(data, "status") === false) {
					savedList = savedList.filter(sl => sl !== get(data, "id"));
				}
			}
			return {
				...state,
				savedList: [...savedList],
			}
		}
		case "TOGGLE_SEARCH": {
			return {
				...state,
				search: get(action, "data", !state.search),
			}
		}
		case "REGISTER_FORM": {
			return {
				...state,
				registerFormData: {
					...state.registerFormData,
					...get(action, "data", {})
				}
			}
		}
		default:
			return state;
	}
}