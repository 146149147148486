import React, { useEffect, useState } from 'react';
import ModalBase from './ModalBase';
import style from './style.module.css';
import $ from "jquery-slim";

function DialogBox({ title, type, className = false, onClose = () => { }, content, children }) {
    const [openClass, setOpenClass] = useState('');
    const [closeClass, setCloseClass] = useState('');
    let openTimer = 0;
    let closeTimer = 0;

    useEffect(() => {
        openTimer = setTimeout(() => {
            setOpenClass(` ${style.show}`);
        }, 50);
        document.addEventListener("keyup", keyup);
        $('html').addClass('overflow-hidden');
        return () => {
            document.removeEventListener("keyup", keyup);
            $('html').removeClass('overflow-hidden');
            clearTimeout(openTimer);
            clearTimeout(closeTimer);
        }
    }, []);

    const keyup = (e) => {
        if (e.key === 'Escape') {
            onCloseHandler();
        }
    };

    const onCloseHandler = (callPropsFunc) => {
        if (callPropsFunc === undefined) {
            callPropsFunc = true;
        }
        if (callPropsFunc) {
            setCloseClass(` ${style.hide}`);
            closeTimer = setTimeout(() => {
                onClose();
            }, 650);
        }
    };

    if (type === 'full-screen') {
        return (
            <ModalBase>
                <div className={`${style.modal}${openClass}${closeClass}`}>
                    {content && content(onCloseHandler)}
                    {children}
                </div>
            </ModalBase>
        )
    }

    return <ModalBase>
        <div className={`${style.modal}${openClass}${closeClass}`}>
            <div className={`${style.dialogBoxOverlayWrap}${className ? ` ${className}` : ''}`}>
                <div className={`${style.dialogBoxOverlay}`}>
                    <div className={`${style.dialogContent}`}>
                        {content && content(onCloseHandler)}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </ModalBase>
}

export default DialogBox;