import React from 'react';
import { connect } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import Input from "../Input";
import style from "../style.module.css";

class Other extends React.Component {
    componentDidMount() {
        const { input: { name } } = this.props;
        const inputs = document.getElementsByName(`${name}_dropdown_multi_other_text`);
        if (inputs.length > 0) {
            inputs[0].focus();
        }
    }

    render() {
        const { input: { name }, onValueChange } = this.props;
        return (
            <div className={`${style.textbox} ${style.selectMultipleBoxOther}`}>
                <Field name={`${name}_dropdown_multi_other_text`} component={Input} label="Please specify" placeholder="Enter other" onChange={value => onValueChange(value)} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownprops) => {
    const { input: { name } } = ownprops;
    let valueArr = JSON.parse(ownprops.input.value || "[]");
    const other = valueArr.find(v => v.id === "Other" && v.value !== "Other");
    if (other) {
        return {
            initialValues: {
                [`${name}_dropdown_multi_other_text`]: other.value,
            }
        }
    }
    return {}
}

export default connect(mapStateToProps)(reduxForm({
    form: 'ddm'
})(Other));