import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import $ from 'jquery-slim';
import style from './style.module.css';

const DarkGuest = () => {
    const [dimesions, setDimensions] = useState({ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight });
    useEffect(() => {
        $("html").removeClass(`lightTheme`).addClass(`darkTheme`);

        function updateDimensions() {
            setDimensions({
                height: $(`.${style.header}`).outerHeight(),
                winWidth: $(window).width(),
                winHeight: $(window).height()
            });
        }
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('scroll', handleHeaderAnimation);
        updateDimensions();
        return () => {
            window.removeEventListener('resize', updateDimensions);
            window.removeEventListener('scroll', handleHeaderAnimation);
        }
    }, [])

    const handleHeaderAnimation = () => {
        const top = window.scrollY;
        if (top > 10) {
            $(`.${style.header}`).addClass(`${style.fixedHeader}`);
        } else {
            $(`.${style.header}`).removeClass(`${style.fixedHeader}`);
        }
    }

    return (
        <div className={`${style.content}`}>
            <Outlet context={{ height: dimesions.height, winWidth: dimesions.winWidth, winHeight: dimesions.winHeight }} />
        </div>
    )
}

export default DarkGuest;