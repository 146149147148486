import React from 'react';
import style from './style.module.css';

export default class Tooltip extends React.Component {
    static defaultProps = {
        direction: "top"
    };

    render() {
        const { direction, children } = this.props;
        const styles = {
            "top": style.top,
            "top-right": `${style.top} ${style.right}`,
            "bottom": style.bottom
        };
        return (
            <div className={`${style.toolTip} ${styles[direction]}`}>
                <span className={style.toolTipIcon}>
                    {
                        direction.substr(0, 3) === "top" && (
                            <i className="fas fa-caret-down"></i>
                        )
                    }
                    {
                        direction === "bottom" && (
                            <i className="fas fa-caret-top"></i>
                        )
                    }
                </span>
                <div className={style.center}>
                    <div className={style.scroller}>{children}</div>
                </div>
            </div>
        );
    }
};