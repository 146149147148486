import { get } from "lodash";
import { setCookie } from "../helpers/storage";

let defaultState = {
	data: {},
	status: false,
	fetching: false,
	serverError: [],
	formData: {}
}

export default function submitReducer(state = {
	login: defaultState,
	forgot: defaultState,
	register: defaultState,
	contact: defaultState,
	register_complete: defaultState,
	send_otp: defaultState,
	verify_otp: defaultState,
	resend_otp: defaultState,
	verifyHash: defaultState,
	reset: defaultState,
	rate: defaultState,
	save: defaultState,
	profile: defaultState,
	profiledelete: defaultState,
	membershipcancel: defaultState,
	membershipdelete: defaultState,
	changeplan: defaultState,
	changeplanpayment: defaultState,
	changePayment: defaultState,
	renewmembership: defaultState,
	newdata: {}
}, action) {
	switch (action.type) {
		case "SUBMIT_PENDING": {
			const { meta: { attribute, formData } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					fetching: true,
					formData
				}
			}
		}
		case "SUBMIT_REJECTED": {
			const { meta: { attribute } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					serverError: get(action.payload, 'response.data'),
					status: get(action.payload, 'response.status'),
					formData: get(action.payload, 'response.data')
				}
			}
		}
		case "SUBMIT_FULFILLED": {
			const { meta: { attribute, formData }, payload: { data, status } } = action;
			if (attribute === "register_complete") {
				setCookie(1, "newUser");
			}
			return {
				...state,
				[attribute]: {
					...defaultState,
					data,
					status,
					serverError: [],
					formData
				}
			}
		}
		case "FORM_CONTINUE": {
			let response = action.payload;
			return {
				...state,
				newdata: {
					...state.newdata,
					...response
				}
			}
		}
		case "SUBMIT_RESET": {
			const { meta: { attribute } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					status: false,
					serverError: {}
				}
			}
		}
		case "ASSIGN_ERROR": {
			const { attribute, error } = get(action, "data", {});
			return {
				...state,
				[attribute]: {
					...state[attribute],
					customError: error
				}
			}
		}
		default:
			return state;
	}
}