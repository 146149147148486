import { get, isEmpty, isObject } from 'lodash';

const generateUrl = (src, size = '') => {
    //Check if source is object
    if (isObject(src)) {
        let image_url = "";
        let { name, extension, updated_at, direct_url, id = false } = src;
        id = id ? id : name;

        if (!isEmpty(direct_url)) {
            return direct_url;
        }

        if (size === "original") {
            image_url = `/uploads/${id}`;
        }
        else if (src.type === "image") {
            const w = get(size.split('x'), "[0]", 0);
            const h = get(size.split('x'), "[1]", 0);
            if (extension === "jpg" || extension === "jpeg" || extension === "png") {
                image_url = `/image/${w}x${h}/${id}`;
            }
            else {
                image_url = `/uploads/${id}`;
            }
        }
        else {
            image_url = `/uploads/${id}`;
        }

        if (updated_at) {
            image_url = image_url + "?v=" + updated_at;
        }

        return process.env.REACT_APP_IMAGES + image_url;
    }

    return src;
}

export {
    generateUrl
}