import React from 'react';
import OtpInput from 'react18-input-otp';
import style from './style.module.css';

export default class OTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.input.value
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (value !== prevProps.input.value) {
            this.setState({
                value
            })
        }
    }

    onChangeHandler = (value) => {
        const { input: { onChange } } = this.props;
        onChange(value);
        this.setState({
            value
        })
    }

    render() {
        const { separator = "", numInputs = 4, serverError = false } = this.props;
        const { value } = this.state;
        return (
            <div className={`${style.formGroup} ${style.noLabel}`}>
                <OtpInput
                    value={value}
                    containerStyle={style.otpInputContainer}
                    inputStyle={style.otpInput}
                    onChange={this.onChangeHandler}
                    numInputs={numInputs}
                    separator={separator}
                    isInputNum={true}
                />
                {
                    serverError && (
                        <p className={style.otpError}>{serverError}</p>
                    )
                }
            </div>
        )
    }
}